
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

































































































































































.aw-slider-videos {
  overflow: hidden;
}

.aw-video-slider__heading__title,
.aw-video-slider__heading__introduction {
  @extend %text-center;

  padding: 0 col(1);

  @include mq(l) {
    padding: 0 col(2, 10);
  }
}

.aw-video-slider__slider {
  height: fit-content;

  ::v-deep .flexible-slider-inner {
    border-top: none;
  }

  ::v-deep .flexible-slider-container {
    height: 30rem;
    margin-top: 0;
  }

  ::v-deep .slidy {
    align-items: flex-start;
  }

  ::v-deep .slidy__item {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    max-width: 85vw;
    padding-right: $spacing;
  }

  @include mq(l) {
    ::v-deep .flexible-slider-container {
      height: 37.5rem;
      margin-top: 0;
    }
  }
}

.slider__item__button {
  @extend %button-nostyle;

  position: relative;
  flex: 1;
  overflow: hidden;
  aspect-ratio: 4/3;
  border-radius: 0.8rem;

  span {
    @extend %visually-hidden;
  }

  @include mq(l) {
    width: auto;
    aspect-ratio: 16/9;
  }
}

.slider__item__button__picture {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: 0.2s $ease-out-cubic;
  transition-property: transform, opacity;
  will-change: transform, opacity;

  img {
    @include image-fit;

    border-radius: 0.8rem;
  }

  .video-playing & {
    opacity: 0;
  }

  @include mq(m) {
    .slider__item__button:hover &,
    .slider__item__button:focus & {
      transform: scale(1.05);
    }
  }
}

.slider__item__button__video {
  @include image-fit;
}

.slider__item__button__icon {
  --play-width: 5rem;

  @extend %button-nostyle;
  @include center-xy;

  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: var(--play-width);
  height: var(--play-width);
  color: $c-white;
  text-align: center;

  &::after {
    @include get-all-space;

    content: '';
    z-index: -1;
    background: $c-mustard;
    border-radius: 50%;
    transition: 0.2s ease-out;
  }

  @include mq(m) {
    .slider__item__button:hover &::after,
    .slider__item__button:focus &::after {
      transform: scale(1.1);
    }
  }
}

.slider__item__text {
  margin-top: $spacing * 0.75;
  padding-right: $spacing;
}
